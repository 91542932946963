<template>
    <div class="System full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height">
            <div class="content-area pa-8 flex-grow-1 bg-white">
                <v-row>
                    <v-col cols="4">
                        <router-link to="/calsettings" v-ripple class="content-btn calibration mb-6"
                                     :class="{'disabled': !checkUserRights('systemCalSettings')}">
                            <p>
                                {{ $t('system.calSettings.calSettings') }}
                            </p>
                        </router-link>
                        <router-link to="/media" v-ripple class="content-btn media mb-6"
                                     :class="{'disabled': !checkUserRights('media')}">
                            <p>
                                {{ $t('system.media.media') }}
                            </p>
                        </router-link>
                        <router-link to="/heater" v-ripple class="content-btn temperature mb-6"
                                     :class="{'disabled': !checkUserRights('systemHeaterSettings')}">
                            <p>
                                {{ $t('system.heater.heaterSettings') }}
                            </p>
                        </router-link>
                        <router-link to="/stations" v-ripple class="content-btn stations mb-6"
                                     :class="{'disabled': !checkUserRights('systemStations')}">
                            <p>
                                {{ $t('system.stationsSettings.stations') }}
                            </p>
                        </router-link>
                        <router-link to="/user-management" v-ripple class="content-btn user"
                                     :class="{'disabled': !checkUserRights('userManagement')}">
                            <p>
                                {{ $t('system.userManagement.userManagement') }}
                            </p>
                        </router-link>
                    </v-col>
                    <v-col cols="4">
                        <router-link to="/pdf-export" v-ripple class="content-btn pdf mb-6"
                                     :class="{'disabled': !checkUserRights('systemPDF')}">
                            <p>
                                {{ $t('system.pdfExport.pdfExport') }}
                            </p>
                        </router-link>
                        <router-link to="/network" v-ripple class="content-btn network mb-6"
                                     :class="{'disabled': !checkUserRights('systemNetwork')}">
                            <p>
                                {{ $t('system.network.network') }}
                            </p>
                        </router-link>
                        <router-link to="/share" v-ripple class="content-btn share mb-6"
                                     :class="{'disabled': !checkUserRights('systemShare')}">
                            <p>
                                {{ $t('system.share.path') }}
                            </p>
                        </router-link>
                        <router-link to="/printer" v-ripple class="content-btn print mb-6"
                                     :class="{'disabled': !checkUserRights('systemPrinter')}">
                            <p>
                                {{ $t('system.printer.printer') }}
                            </p>
                        </router-link>
                        <router-link to="/backup" v-ripple class="content-btn backup"
                                     :class="{'disabled': !checkUserRights('backup')}">
                            <p>
                                {{ $t('system.backup.backup') }}
                            </p>
                        </router-link>
                    </v-col>
                    <v-col cols="4">
                        <router-link to="/auditTrail" v-ripple class="content-btn auditTrail mb-6"
                                     :class="{'disabled': !checkUserRights('systemAudit')}">
                            <p>
                                {{ $t('system.audit.auditTrail') }}
                            </p>
                        </router-link>
                        <router-link to="/service" v-ripple class="content-btn service mb-6"
                                     :class="{'disabled': !checkUserRights('systemService')}">
                            <p>
                                {{ $t('system.service.service') }}
                            </p>
                        </router-link>
                        <router-link to="/firmwareupdate" v-ripple class="content-btn update mb-6"
                                     :class="{'disabled': !checkUserRights('firmwareupdate')}">
                            <p>
                                {{ $t('system.firmwareupdate.firmwareupdate') }}
                            </p>
                        </router-link>
                        <router-link to="/system-settings" v-ripple class="content-btn system mb-6"
                                     :class="{'disabled': !checkUserRights('systemSettings')}">
                            <p>
                                {{ $t('system.systemSettings') }}
                            </p>
                        </router-link>
                        <router-link to="/system-info" v-ripple class="content-btn systeminfo"
                                     :class="{'disabled': !checkUserRights('systemInfo')}">
                            <p>
                                {{ $t('system.systemInfo') }}
                            </p>
                        </router-link>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>

import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'System',
    components: {},
    props: {},
    data() {
        return {}
    },
    computed: {
        ...mapState([
        ]),
    },
    methods: {},
    mixins: [mixins],

}
</script>
<style scoped lang="scss">
.v-application .content-btn {
    justify-content: left;

    &::before {
        content: "";
        width: 30px;
        height: 20px;
        margin-right: 10px;
        margin-bottom: 3px;
        background-size: contain;
        background-position: center;
    }

    &.auditTrail {
        &::before {
            background-image: url("../assets/cabinet-filing-solid_weiss.svg");
            height: 21px;
        }
    }

    &.backup {
        &::before {
            background-image: url("../assets/backup_weiss.svg");
            height: 23px;
            margin-bottom: 8px;
        }
    }

    &.calibration {
        &::before {
            background-image: url("../assets/dot-circle-regular_weiss.svg");
            height: 22px;
        }
    }

    &.systeminfo {
        &::before {
            background-image: url("../assets/info_weiss.svg");
        }
    }

    &.media {
        &::before {
            height: 22px;
            background-image: url("../assets/media_weiss.svg");
        }
    }

    &.network {
        &::before {
            background-image: url("../assets/network_weiss.svg");
            height: 22px;
        }
    }

    &.pdf {
        &::before {
            height: 23px;
            background-image: url("../assets/pdf_weiss.svg");
        }
    }

    &.print {
        &::before {
            background-image: url("../assets/print_weiss.svg");
            height: 23px;
        }
    }

    &.temperature {
        &::before {
            height: 30px;
            background-image: url("../assets/temperature_weiss.svg");
        }
    }

    &.service {
        &::before {
            background-image: url("../assets/tools-solid_weiss.svg");
        }
    }

    &.share {
        &::before {
            background-image: url("../assets/server-solid_weiss.svg");
        }
    }

    &.stations {
        &::before {
            background-image: url("../assets/link-solid_weiss.svg");
        }
    }

    &.system {
        &::before {
            height: 23px;
            background-image: url("../assets/settings_weiss.svg");
        }
    }

    &.update {
        &::before {
            background-image: url("../assets/microchip-solid_weiss.svg");
        }
    }

    &.user {
        &::before {
            background-image: url("../assets/users-setting_weiss.svg");
        }
    }
}

</style>